import firebase from "firebase/app"
import "@firebase/analytics"
import "firebase/firestore"

const config = {
  apiKey: "AIzaSyCutE0rjGxZcq2rzfmPYziTE7jOB0R1wdA",
  authDomain: "polaris-site.firebaseapp.com",
  databaseURL: "https://polaris-site.firebaseio.com",
  projectId: "polaris-site",
  storageBucket: "polaris-site.appspot.com",
  messagingSenderId: "168594181789",
  appId: "1:168594181789:web:b4812bb6e00d2ac6fe4e74",
  measurementId: "G-4JSJYM4H5G",
}
 
firebase.initializeApp(config)
firebase.analytics()

const db = firebase.firestore()

export { db }
